html {
  //scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  max-width: 100%;
  overflow-x: hidden;
  max-height: 100%;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
}

body {
  background-color: rgb(25, 0, 0);
  color: white;
}

section {
  height: 80vh;
  //scroll-snap-align: center;
}