
.hero {
  height: 100vh;
  position: relative;
  background: linear-gradient(180deg, rgb(25, 0, 0), rgb(50, 0, 0));
  z-index: -1;

  .wrapper {
    height: 100%;
    margin-left: 30vh;

    .textContainer {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 40px;

      h2 {
        font-size: 30px;
        color: rgb(200, 0, 0);
        letter-spacing: 10px;
      }
      
      h1 {
        font-size: 88px;
      }
    }
    
    z-index: 1;
  }

  .imageContainer {
    margin-top: 20vh;
    margin-bottom: 20vh;
    position: absolute;
    right: 0;
    top: 0;

    img {
      margin-right: 30vh;
      height: 80vh;
    }
  }

  .slidingTextContainer {
    position: absolute;
    font-size: 40vh;
    bottom: 0;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.15);
    width: 50%;
    font-weight: bold;;
  }
}
